import React from 'react';

import { PromoTab } from './components/PromoTab';
import { IPromoTabsProps } from './PromoTabs.types';

export const PromoTabs: React.FC<IPromoTabsProps> = ({ tabs, handleTabClick, currentTab }) => {
    return (
        <>
            {tabs.map((tab) => (
                <PromoTab data={tab} isActive={tab.id === currentTab} key={tab.id} handleTabClick={handleTabClick} />
            ))}
        </>
    );
};
