import { AppRoute } from 'constants/AppRoute';
import { HeaderNavItems } from 'constants/nav-items';
import { promoApps } from 'constants/promo-apps';
import { QueryParam } from 'constants/QueryParam';

import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ContentContainer } from 'containers/ContentContainer';
import { HeaderContainer } from 'containers/HeaderContainer';
import { HomePromoSection } from 'containers/HomePromoSection';
import { MainContainer } from 'containers/MainContainer';
import { useQuery } from 'hooks/useQuery';
import { ReactComponent as Logo } from 'icons/logo.svg';

import { HeaderNav } from 'components/HeaderNav';
import { HomePageContent } from 'components/HomePageContent';

export const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const query = useQuery();

    const selectedTab = !isNaN(Number(query.get('tab'))) ? query.get('tab') : undefined;

    const handleLogoClick = useCallback(() => document.documentElement.scrollTo(0, 0), []);

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (query.get(QueryParam.Tab) !== null) {
            document.documentElement.scrollTo(0, 0);
        }

        if (
            query.get('tab') === null ||
            isNaN(Number(query.get('tab'))) ||
            Number(query.get('tab')) <= 0 ||
            Number(query.get('tab')) > promoApps.length
        ) {
            navigate(AppRoute.Home());
        }
    }, [query, navigate]);

    return (
        <>
            <HeaderContainer isDynamicBg>
                <header className="header container">
                    <Link
                        to={`${AppRoute.Home()}${selectedTab ? `?tab=${selectedTab}` : ''}`}
                        onClick={handleLogoClick}
                    >
                        <Logo className="header-logo" />
                    </Link>
                    <HeaderNav items={HeaderNavItems} />
                </header>
            </HeaderContainer>
            <MainContainer>
                <HomePromoSection />
                <ContentContainer>
                    <HomePageContent />
                </ContentContainer>
            </MainContainer>
        </>
    );
};
