import { AppRoute } from 'constants/AppRoute';
import { HeaderNavItems } from 'constants/nav-items';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { ContentContainer } from 'containers/ContentContainer';
import { HeaderContainer } from 'containers/HeaderContainer';
import { MainContainer } from 'containers/MainContainer';
import { ReactComponent as Logo } from 'icons/logo.svg';

import { HeaderNav } from 'components/HeaderNav';

const CnContent = cn('content');

export const GoogleApiDisclosure: React.FC = () => {
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderContainer customClassName="header-container_black">
                <header className="header container">
                    <Link to={AppRoute.Home()}>
                        <Logo className="header-logo" />
                    </Link>
                    <HeaderNav items={HeaderNavItems} />
                </header>
            </HeaderContainer>
            <MainContainer>
                <ContentContainer>
                    <div className="container">
                        <div className={CnContent()}>
                            <div className={CnContent('textBlock')}>
                                <h1 className={CnContent('title')}>Google API Disclosure</h1>
                            </div>
                            <div className={CnContent('textBlock')}>
                                <div className={CnContent('text')}>
                                    <span>
                                        My Converter: Music & Audio - use of information received from Google APIs will adhere to
                                    </span>
                                    <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"> Google API Services User Data Policy</a>
                                    <span>
                                        , including the Limited Use requirements.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </MainContainer>
        </>
    );
};
