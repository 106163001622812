import { ApiRoute } from 'constants/ApiRoute';

import { IFormValues } from 'types/form.types';

const DATA_STUB = 'Unknown';

export const postQuestion = async ({
    name = DATA_STUB,
    email,
    message,
    country = DATA_STUB,
    application = DATA_STUB,
    theme,
}: IFormValues): Promise<Response> =>
    await fetch(ApiRoute.Mail, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
            name,
            email,
            message,
            country,
            application,
            theme,
        }),
    });
