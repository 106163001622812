import React from 'react';
import { cn } from '@bem-react/classname';
import { Platform } from 'types/promo-slider.types';

import { IPromoSlideProps } from './PromoSlide.types';

const CnPromoSlider = cn('promoSlider');

export const PromoSlide: React.FC<IPromoSlideProps> = ({ src, platform, onClick, isExpanded }) => {
    const handleSlideClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        onClick?.();
    };

    return (
        <div
            className={CnPromoSlider('slide', { mobile: platform === Platform.Ios, expanded: isExpanded })}
            onClick={handleSlideClick}
        >
            <img src={src} alt="slide" draggable={false} />
        </div>
    );
};
