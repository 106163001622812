import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { IHeaderNavItemProps } from './HeaderNavItem.types';

import './HeaderNavItem.scss';

export const HeaderNavItem: React.FC<IHeaderNavItemProps> = ({ item }) => {
    const { name, href } = item;
    return (
        <li className="nav-item">
            <Link
                to={{
                    pathname: '/',
                    hash: href,
                    search: document.location.search,
                }}
            >
                {name}
            </Link>
        </li>
    );
};
