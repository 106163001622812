import { AppRoute } from 'constants/AppRoute';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from 'containers/Layout';
import { ContactUsPage } from 'pages/ContactUsPage';
import { EulaPage } from 'pages/EulaPage';
import { HomePage } from 'pages/HomePage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { GoogleApiDisclosure } from 'pages/GoogleApiDisclosure';
import { ConsentForPersonalDataProcessing } from 'pages/ConsentForPersonalDataProcessing';

export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={AppRoute.Home()} element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path={AppRoute.Privacy()} element={<PrivacyPage />} />
                    <Route path={AppRoute.Eula()} element={<EulaPage />} />
                    <Route path={AppRoute.GoogleApiDisclosure()} element={<GoogleApiDisclosure />} />
                    <Route path={AppRoute.ConsentForPersonalDataProcessing()} element={<ConsentForPersonalDataProcessing />} />
                    <Route path={AppRoute.Contact()} element={<ContactUsPage />} />
                    <Route path={AppRoute.NotFound()} element={<NotFoundPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
