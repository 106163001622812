import React, { MouseEventHandler, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { motion } from 'framer-motion';
import { ReactComponent as ArrowLeft } from 'icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';

import { PaginationItem } from './components/PaginationItem';
import { PromoSlide } from './components/PromoSlide';
import { IPromoSliderSmallProps } from './PromoSliderSmall.types';

import './PromoSliderSmall.scss';

const CnPromoSlider = cn('promoSlider');

export const PromoSliderSmall: React.FC<IPromoSliderSmallProps> = ({
    slides,
    currentSlide = 1,
    setCurrentSlide,
    onSlideClick,
    isExpanded,
}) => {
    const sliderWindowRef: RefObject<HTMLDivElement> = useRef(null);
    const [animateValue, setAnimateValue] = useState(0);

    const handlePrevClick: MouseEventHandler = useCallback(
        (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            setCurrentSlide((current) => {
                if (current > 1) {
                    return current - 1;
                }
                return current;
            });
        },
        [setCurrentSlide],
    );

    const handleNextClick: MouseEventHandler = useCallback(
        (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            setCurrentSlide((current) => {
                if (current < slides.length) {
                    return current + 1;
                }
                return current;
            });
        },
        [slides.length, setCurrentSlide],
    );

    const handlePaginationClick = useCallback(
        (order: number) => {
            setCurrentSlide(order);
        },
        [setCurrentSlide],
    );

    useEffect(() => {
        if (currentSlide > slides.length) {
            setCurrentSlide(slides.length);
        }
    }, [currentSlide, setCurrentSlide, slides.length]);

    useEffect(() => {
        if (sliderWindowRef.current) {
            setAnimateValue((1 - currentSlide) * sliderWindowRef.current.getBoundingClientRect().width);
        }
    }, [currentSlide, isExpanded, slides]);

    return (
        <div
            className={CnPromoSlider({
                expanded: isExpanded,
            })}
        >
            <div className={CnPromoSlider('arrowLeft', { disabled: currentSlide <= 1 })} onClick={handlePrevClick}>
                <ArrowLeft />
            </div>
            <div className={CnPromoSlider('sliderWindow')} ref={sliderWindowRef}>
                <motion.div
                    className={CnPromoSlider('innerWrapper')}
                    animate={{
                        x: animateValue,
                    }}
                    transition={{
                        duration: 0.3,
                    }}
                >
                    {slides.map((slideData) => (
                        <PromoSlide {...slideData} key={slideData.src} onClick={onSlideClick} isExpanded={isExpanded} />
                    ))}
                </motion.div>
            </div>
            <div
                className={CnPromoSlider('arrowRight', { disabled: currentSlide >= slides.length })}
                onClick={handleNextClick}
            >
                <ArrowRight />
            </div>
            <div className={CnPromoSlider('pagination')}>
                {slides.map((_, index) => (
                    <PaginationItem
                        key={index}
                        isActive={index === currentSlide - 1}
                        order={index + 1}
                        handleClick={handlePaginationClick}
                    />
                ))}
            </div>
        </div>
    );
};
