export const appsList = [
    'Select',
    'Widgetter - Custom Desktop',
    'Audio One: Easy Music Editing',
    'Mix One - Mix Pads & Mashup Hero',
    'My Converter: Music & Audio',
    'FitGet: GPS Sport Tracker App',
    'Voice Memos - Audio Recorder',
    'MP3 Tag Editor: Edit Metadata',
    'No Insomnia - ASMR Relax Sounds',
];