import { AppRoute } from 'constants/AppRoute';
import { HeaderNavItems } from 'constants/nav-items';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { ContentContainer } from 'containers/ContentContainer';
import { HeaderContainer } from 'containers/HeaderContainer';
import { MainContainer } from 'containers/MainContainer';
import { ReactComponent as Logo } from 'icons/logo.svg';

import { HeaderNav } from 'components/HeaderNav';

const CnContent = cn('content');

export const ConsentForPersonalDataProcessing: React.FC = () => {
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderContainer customClassName="header-container_black">
                <header className="header container">
                    <Link to={AppRoute.Home()}>
                        <Logo className="header-logo" />
                    </Link>
                    <HeaderNav items={HeaderNavItems} />
                </header>
            </HeaderContainer>
            <MainContainer>
                <ContentContainer>
                    <div className="container">
                        <div className={CnContent()}>
                            <div className={CnContent('textBlock')}>
                                <h1 className={CnContent('title')}>Consent for Personal Data Processing</h1>
                            </div>

                            <div className={CnContent('textBlock')}>
                                <p className={CnContent('text')}>
                            I confirm that I have read the privacy policy located at <a href="https://bitnite.studio/privacy"> https://bitnite.studio/privacy</a> and give my consent to Bitnight LLP to process personal data.
                                </p>
                            </div>


                            <div className={CnContent('textBlock')}>
                            Purpose of processing personal data:
                                <ol className={CnContent('text')}>
                                    <li>
                            - establishing and maintaining communication between the subject of personal data and the Operator
                                    </li>
                                </ol>
                            </div>

                            <div className={CnContent('textBlock')}>
                                <p className={CnContent('text')}>
                                    List of personal data for which consent is given for processing:
                                </p>
                                <ol className={CnContent('text')}>
                                    <li>
                                        - Name;
                                    </li>
                                    <li>
                                        - E-mail address;
                                    </li>
                                    <li>
                                        - another provided by me Personal Data.
                                    </li>
                                </ol>
                            </div>

                            <div className={CnContent('textBlock')}>
                                <p className={CnContent('text')}>
                                The processing of the above personal data will be carried out through automated processing of personal data.
                                </p>
                                <p className={CnContent('text')}>
                                List of actions that the Operator is allowed to do in relation to the processing of personal data: collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer with consent, depersonalization, blocking, deletion, destruction of personal data (only those that are actually used).
                                </p>
                                <p className={CnContent('text')}>
                                This consent to the processing of personal data is valid from the moment it is submitted to the operator until the purposes of processing are achieved or for the period of validity of the contract and can be revoked by me at any time by submitting an application to the operator in simple written form by sending an email to <a href="mailto:support@bitnite.studio"> support@bitnite.studio</a>.
                                </p>
                            </div>

                            <div className={CnContent('textBlock')}>
                                <p className={CnContent('text')}>
                                    Personal data is destroyed: to achieve the purposes of processing personal data; upon liquidation or reorganization of the Operator; on the basis of a written request from the subject of personal data with a request to terminate the processing of his personal data.
                                </p>
                            </div>

                        </div>
                    </div>
                </ContentContainer>
            </MainContainer>
        </>
    );
};
