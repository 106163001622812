import { promoApps } from 'constants/promo-apps';
import { QueryParam } from 'constants/QueryParam';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { PromoSliderSmall } from 'containers/PromoSliderPrimary';
import { motion } from 'framer-motion';
import { useCloseByEsc } from 'hooks/useCloseByEsc';
import { useOverflow } from 'hooks/useOverflow';
import { useQuery } from 'hooks/useQuery';
import { ReactComponent as AppStoreIos } from 'icons/app-store-ios.svg';
import { ReactComponent as AppStoreMac } from 'icons/app-store-mac.svg';
import { ReactComponent as AppStoreTV } from 'icons/app-store-tv.svg';
import { Platform } from 'types/promo-slider.types';
import { IPromoApp } from 'types/promo-tabs.types';

import { PromoTabs } from 'components/PromoTabs';

import './HomePromoSection.scss';

const CnPromo = cn('promo');

const TAB_DATA_STUB: IPromoApp = {
    id: 0,
    appName: '',
    title: '',
    subtitle: '',
    description: '',
    bgImage: '',
    icon: '',
    storeLink: [],
    bigSlides: [],
    smallSlides: [],
};

export const HomePromoSection: React.FC = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [currentSlide, setCurrentSlide] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    const currentTab =
        query.get('tab') !== null && !isNaN(Number(query.get('tab'))) && Number(query.get('tab')) <= promoApps.length
            ? Number(query.get(QueryParam.Tab))
            : 1;

    const { title, subtitle, description, bgImage, smallSlides, storeLink, bigSlides } = useMemo(
        () => promoApps.find((item) => item.id === currentTab) || TAB_DATA_STUB,
        [currentTab],
    );

    const handleTabClick = useCallback(
        (tabId: number) => {
            query.set(QueryParam.Tab, String(tabId));
            navigate({
                pathname,
                search: query.toString(),
            });
            setCurrentSlide(1);
        },
        [navigate, query, pathname],
    );

    useEffect(() => {
        setCurrentSlide(1);
    }, [query]);

    const handleCloseByEsc = useCallback(() => {
        setIsExpanded(false);
    }, []);

    const handleSlideClick = useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, []);

    useCloseByEsc(handleCloseByEsc);
    useOverflow(isExpanded);

    useEffect(() => {
        const handleScroll = () => {
            setIsExpanded(false);
        };

        if (isExpanded) {
            document.addEventListener('scroll', handleScroll);
        }
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [isExpanded]);

    const handelSliderWrapperClick = () => setIsExpanded((prev) => (prev ? false : prev));

    return (
        <motion.section
            className={CnPromo()}
            style={{ backgroundImage: `url(${bgImage})` }}
            initial={{
                backgroundColor: '#090909',
                opacity: 0.4,
            }}
            animate={{
                opacity: 1,
            }}
        >
            <div className={CnPromo('contentWrapper')}>
                <div className={CnPromo('row')}>
                    <div className={CnPromo('textGroup')}>
                        <div className={CnPromo('text')}>
                            <h2 className={CnPromo('title')}>{title}</h2>
                            <span className={CnPromo('subtitle')}>{subtitle}</span>
                            <p className={CnPromo('description')}>{description}</p>
                        </div>
                        <div className={CnPromo('links')}>
                            {storeLink.map((linkData, index) => (
                                <a key={index} href={linkData.src} target="_blank" rel="noreferrer">
                                    {linkData.platform === Platform.Ios ? <AppStoreIos />
                                    : linkData.platform === Platform.Mac ? <AppStoreMac />
                                    : linkData.platform === Platform.tvOS ? <AppStoreTV />
                                    : null}
                                </a>
                            ))}
                        </div>



                    </div>
                    <div
                        className={CnPromo('sliderWrapper', { expanded: isExpanded })}
                        onClick={handelSliderWrapperClick}
                    >
                        <div
                            className={CnPromo('sliderWrapper-content', {
                                expanded: isExpanded,
                            })}
                        >
                            <PromoSliderSmall
                                slides={isExpanded ? bigSlides : smallSlides}
                                currentSlide={currentSlide}
                                setCurrentSlide={setCurrentSlide}
                                onSlideClick={handleSlideClick}
                                isExpanded={isExpanded}
                            />
                        </div>
                    </div>
                </div>
                {!isExpanded && (
                    <div className={CnPromo('tabs')}>
                        <PromoTabs tabs={promoApps} handleTabClick={handleTabClick} currentTab={currentTab} />
                    </div>
                )}
            </div>
        </motion.section>
    );
};
