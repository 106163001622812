import { Platform } from 'types/promo-slider.types';
import { IPromoApp } from 'types/promo-tabs.types';

export const promoApps: IPromoApp[] = [
    {
        id: 1,
        appName: 'Audio One: Easy Music Editing',
        title: 'Audio One: Easy Music Editing',
        subtitle: 'Sound Editor & Ringtone Maker',
        description:
            'Audio One is a functional and simple, user friendly audio editor fully adapted for Mac. In short, this is exactly what you were looking for. Audio One is suitable for beginners and experienced sound designers.',
        bgImage: '/assets/bg/audio-one-bg.png',
        icon: '/assets/tab-icons/audio-one-icon.png',
        storeLink: [
            {
                platform: Platform.Mac,
                src: 'https://apps.apple.com/us/app/id1589899916',
            },
            {
                platform: Platform.Ios,
                src: 'https://apps.apple.com/us/app/id1607950988',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/big/01.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/big/02.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/big/03.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/big/04.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/big/05.png',
            },
            // iOS
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/big/01.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/big/02.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/big/03.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/big/04.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/big/05.png',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/small/01.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/small/02.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/small/03.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/small/04.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-one-mac/small/05.jpg',
            },
            //iOS
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/small/01.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/small/02.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/small/03.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/small/04.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-one-ios/small/05.jpg',
            },
        ],
    },
    {
        id: 2,
        appName: 'Mix One - Mix Pads & Mashup Hero',
        title: 'Mix One - Mix Pads & Mashup Hero',
        subtitle: 'DJ Mixer: Make Music & Beats',
        description:
            'Create your own music with Mix One: mix, record, save and share. Enjoy this app created both for DJs and newbies. Play with presets or create your own mixing sounds from different presets. Add some awesome audio effects. Make your own awesome music track.',
        bgImage: '/assets/bg/mixone-bg.png',
        icon: '/assets/tab-icons/mixone-icon.png',
        storeLink: [
            {
                platform: Platform.Mac,
                src: 'https://apps.apple.com/us/app/id6468033280',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/big/01.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/big/02.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/big/03.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/big/04.jpg',
            },
             {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/big/05.jpg',
            },
             {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/big/06.jpg',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/small/01.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/small/02.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/small/03.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/small/04.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/small/05.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/mixone/small/06.jpg',
            },
        ],
    },
    {
        id: 3,
        appName: 'My Converter: Music & Audio',
        title: 'My Converter: Music & Audio',
        subtitle: 'Convert to mp3, wav, mp4, flac',
        description:
            'My Converter is really a simple and handy tool for converting audio files of different formats -  mp3, mp4, wav, flac, aiff, aac, m4a and etc.',
        bgImage: '/assets/bg/audio-converter-bg.png',
        icon: '/assets/tab-icons/audio-converter-icon.png',
        storeLink: [
            {
                platform: Platform.Mac,
                src: 'https://apps.apple.com/us/app/id1612790003',
            },
            {
                platform: Platform.Ios,
                src: 'https://apps.apple.com/us/app/id1628980624',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/big/01.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/big/02.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/big/03.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/big/04.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/big/05.png',
            },

            // iOS

            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/big/01.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/big/02.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/big/03.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/big/04.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/big/05.jpeg',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/small/01.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/small/02.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/small/03.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/small/04.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/audio-converter-mac/small/05.png',
            },

            // iOS

            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/small/01.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/small/02.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/small/03.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/small/04.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/audio-converter-ios/small/05.jpeg',
            },
        ],
    },
    {
        id: 4,
        appName: 'FitGet: GPS Sport Tracker App',
        title: 'FitGet: GPS Sport Tracker App',
        subtitle: 'Run, Walking, Ski, Step Track',
        description:
            'Going for a hike, cycling, swimming or running – make FitGet your companion whenever you’re on the move. The app is designed to collect and process the data about the key parameters of any training and wrap it up into concise, digestible graphs.',
        bgImage: '/assets/bg/gps-bg.png',
        icon: '/assets/tab-icons/gps-icon.png',
        storeLink: [
            {
                platform: Platform.Ios,
                src: 'https://apps.apple.com/us/app/id1552129745',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/big/01.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/big/02.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/big/03.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/big/04.png',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/big/05.png',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/small/01.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/small/02.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/small/03.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/small/04.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/gps/small/05.jpg',
            },
        ],
    },
    {
        id: 5,
        appName: 'Voice Memos - Audio Recorder',
        title: 'Voice Memos - Audio Recorder',
        subtitle: 'Speech To Text Notes: Notepad',
        description:
            'Immerse yourself in the world of your own emotions! Record important things with your voice and get audio and text in one place. Merge records and collect memories.',
        bgImage: '/assets/bg/voice-memos-bg.png',
        icon: '/assets/tab-icons/voice-memos-icon.png',
        storeLink: [
            {
                platform: Platform.Mac,
                src: 'https://apps.apple.com/us/app/id1610255722',
            },
            {
                platform: Platform.Ios,
                src: 'https://apps.apple.com/us/app/id1556611763',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/big/01.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/big/02.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/big/03.png',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/big/04.png',
            },
            // iOS
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/big/01.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/big/02.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/big/03.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/big/04.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/big/05.jpg',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/small/01.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/small/02.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/small/03.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/voice-memos-mac/small/04.jpg',
            },
            // iOS
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/small/01.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/small/02.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/small/03.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/small/04.jpeg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/voice-memos-ios/small/05.jpeg',
            },
        ],
    },
    {
        id: 6,
        appName: 'MP3 Tag Editor: Edit Metadata',
        title: 'MP3 Tag Editor: Edit Metadata',
        subtitle: 'Music Library Editing: Tags',
        description:
            'Tidy up your music collection - Tag Editor is here to help. It supports all popular audio formats and can edit lots of tags like artist, title, album, genre, year, cover, composer, copyright etc. Automatic correction and batch mode make it really fast and easy to organize your audio library.',
        bgImage: '/assets/bg/tag-editor-bg.png',
        icon: '/assets/tab-icons/tag-editor-icon.png',
        storeLink: [
            {
                platform: Platform.Mac,
                src: 'https://apps.apple.com/us/app/id1631260641',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/big/01.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/big/02.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/big/03.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/big/04.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/big/05.jpeg',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/small/01.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/small/02.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/small/03.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/small/04.jpeg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/tag-editor/small/05.jpeg',
            },
        ],
    },
    {
        id: 7,
        appName: 'No Insomnia - ASMR Relax Sounds',
        title: 'No Insomnia - ASMR Relax Sounds',
        subtitle: 'Melodies: Be Calm & Sleep App',
        description:
            'Whether you want to relax, meditate, fall asleep or even focus - this application is what you need! A rich collection of sounds for adults and kids will give you a great mood, and cute animations will not leave you indifferent.',
        bgImage: '/assets/bg/no-insomnia.png',
        icon: '/assets/tab-icons/no-insomnia-icon.png',
        storeLink: [
            {
                platform: Platform.Mac,
                src: 'https://apps.apple.com/us/app/id1670040466',
            },
            {
                platform: Platform.Ios,
                src: 'https://apps.apple.com/us/app/id1666142490',
            },
            {
                platform: Platform.tvOS,
                src: 'https://apps.apple.com/us/app/id6444352641',
            },
        ],
        bigSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/big/01.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/big/02.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/big/03.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/big/04.jpg',
            },
            // iOS
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/big/01.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/big/02.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/big/03.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/big/04.jpg',
            },
        ],
        smallSlides: [
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/small/01.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/small/02.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/small/03.jpg',
            },
            {
                platform: Platform.Mac,
                src: '/assets/slides/no-insomnia-mac/small/04.jpg',
            },
            // iOS
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/small/01.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/small/02.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/small/03.jpg',
            },
            {
                platform: Platform.Ios,
                src: '/assets/slides/no-insomnia-ios/small/04.jpg',
            },
        ],
    },
];
