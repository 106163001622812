import { ABOUT_ANCHOR, REMOTE_ANCHOR } from 'constants/common';

import React from 'react';
import { cn } from '@bem-react/classname';
import { motion } from 'framer-motion';

import './HomePageContent.scss';

const CnContentArticle = cn('contentArticle');

export const HomePageContent: React.FC = () => {
    return (
        <div className="homeContent">
            <motion.article
                className={`${CnContentArticle()}`}
                id={ABOUT_ANCHOR}
                initial={{
                    opacity: 0,
                }}
                whileInView={{
                    opacity: 1,
                }}
                transition={{
                    duration: 0.9,
                }}
                viewport={{
                    once: true,
                }}
            >
                <div className="container">
                    <h2 className={CnContentArticle('title')}>About us</h2>
                    <h3 className={CnContentArticle('subtitle')}>Bitnite - if software development is your passion.</h3>
                    <p className={CnContentArticle('textItem')}>
                        We are the group of experts with over 10 years of experience in software development. We know a lot about code writing, workflow optimization, apps analytics, bug finding - this knowledge helps us to create high-performing and high quality Mac & iPhone apps. We have joined up to form a dream team, a team of like-minded people and enthusiasts in their field. At Bitnite we value both our employees and our users equally. Listening to customers feedback and our intuition, we are constantly do our best to improve our apps. Joining our company gives you the chance to work from the ground up, shape the products performance, develop fresh ideas and impress customers all around the world. Every member of our team is respected, and we make an effort to work quickly and efficiently in order to produce excellent outcomes.
                    </p>
                </div>
            </motion.article>
            <motion.article
                className={CnContentArticle()}
                id={REMOTE_ANCHOR}
                initial={{
                    opacity: 0,
                }}
                whileInView={{
                    opacity: 1,
                }}
                transition={{
                    duration: 0.9,
                }}
                viewport={{
                    once: true,
                }}
            >
                <div className="container">
                    <h2 className={CnContentArticle('title')}>Work with us</h2>
                    <div className={CnContentArticle('textWrapper')}>
                        <p className={CnContentArticle('textItem')}>
                            Our company provides the opportunity to work remotely. We welcome enterprising, responsible, result-oriented employees and are always ready to discuss creative ideas and consider reasonable wishes.
                        </p>
                        <div className={CnContentArticle('contactsItem')}>
                            <a className={CnContentArticle('link')} href="mailto:jobs@bitnite.studio">
                                jobs@bitnite.studio
                            </a>
                            <span className={CnContentArticle('caption')}>Want to join our team?</span>
                        </div>
                    </div>
                    <div className={CnContentArticle('textWrapper')}>
                        <p className={CnContentArticle('textItem')} />
                        <div className={CnContentArticle('contactsItem')}>
                            <a className={CnContentArticle('link')} href="mailto:partners@bitnite.studio">
                                partners@bitnite.studio
                            </a>
                            <span className={CnContentArticle('caption')}>Collaboration and partnership</span>
                        </div>
                    </div>
                </div>
            </motion.article>
        </div>
    );
};
