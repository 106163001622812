import { useEffect } from 'react';

export const useOverflow = (isPopupShown: boolean): void => {
    useEffect(() => {
        const header: HTMLDivElement | null = document.querySelector('.header-container');
        const div = document.createElement('div');
        if (isPopupShown) {
            div.style.overflowY = 'scroll';
            div.style.width = '50px';
            div.style.height = '50px';
            document.body.append(div);
            const clientWidth = div.clientWidth;
            const offsetWidth = div.offsetWidth;
            document.body.style.paddingRight = `${offsetWidth - clientWidth}px`;
            if (header !== null) {
                header.style.paddingRight = `${offsetWidth - clientWidth}px`;
            }

            document.body.style.overflow = 'hidden';
        }

        return () => {
            div.remove();
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
            if (header !== null) {
                header.style.paddingRight = '';
            }
        };
    }, [isPopupShown]);
};
